import React, { Fragment, memo } from "react";
import { Card, Col, Row, Container, Form } from "react-bootstrap";

import { Link } from "react-router-dom";

import SubHeader from "../components/widgets/sub-header";
// image
import image from "../../../../assets/modules/landing-pages/images/home-4/contact.webp";

const ContactUs = memo(() => {
  return (
    <Fragment>
      {/* <SubHeader title="Contact Us" /> */}
      <div className="section-padding">
        <Container>
          <Row>
            <Col md={12} lg={4}>
              <Card className="rounded-1 contact-detail-card mb-lg-0" >
                <Card.Body className="text-center h-0" style={{minHeight : '20rem'}}>
                  <div className="services  rounded-pill text-center mx-auto mb-4">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="map-marker-alt"
                      className="svg-inline--fa fa-map-marker-alt fa-w-12 text-primary"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      width="32"
                      height="32"
                    >
                      <path
                        fill="currentColor"
                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                      ></path>
                    </svg>
                  </div>
                  <h5 className="mt-4">Address</h5>
                  <p className="mt-3">Plot No. 131, Second Floor, Gayatri Nagar Society, Dabholi Char Rasta, opp. Gayatri Mandir, Surat, Gujarat 395004</p>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6}>
              <Card className="rounded-1 contact-detail-card mb-lg-0" >
                <Card.Body className="text-center h-0" style={{minHeight : '20rem'}}>
                  <div className="services rounded-pill text-center mx-auto mb-4">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="envelope"
                      className="svg-inline--fa fa-envelope fa-w-16 text-primary"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="32"
                      height="32"
                    >
                      <path
                        fill="currentColor"
                        d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                      ></path>
                    </svg>
                  </div>
                  <h5 className="mt-4">Email Us</h5>
                  <p className="mt-3">sevakect2014@gmail.com</p>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6}>
              <Card className="card rounded-1 contact-detail-card mb-lg-0" >
                <Card.Body className="text-center h-0" style={{minHeight : '20rem'}}>
                  <div className="services rounded-pill text-center mx-auto mb-4">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="phone-alt"
                      className="svg-inline--fa fa-phone-alt fa-w-16 text-primary"                   
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="32"
                      height="32"
                    >
                      <path
                        fill="currentColor"
                        d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"
                      ></path>
                    </svg>
                  </div>
                  <h5 className="mt-4">Call Us</h5>
                  <p className="mt-3">7096543210</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <iframe
          title="google-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7438.060068239723!2d72.81748937587619!3d21.23065758081247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04eba330c9401%3A0xa6db00d4648ac822!2sSevak%20Education%20%26%20Charitable%20Trust!5e0!3m2!1sen!2sin!4v1724827930964!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
     
    </Fragment>
  );
});

ContactUs.displayName = "ContactUs";
export default ContactUs;
