import React, { memo, Fragment, useState, useEffect } from "react";

//React-bootstrap
import { Row, Col, Card, Form, Modal, Button, CardBody } from "react-bootstrap";
//Component
import useSound from 'use-sound';
import CountUp from "react-countup";
//Img
import Swal from "sweetalert2";
import axios from "axios";
import beepSound from '../../../../assets/event/stock/tik.mp3';
import errorSound from '../../../../assets/event/stock/error.mp3';
import { useMyContext } from "../../../../Context/MyContextProvider";
const Scanner = memo(() => {
    const { api, UserData, authToken, formatDateTime } = useMyContext();
    const [QRdata, setQRData] = useState('');
    const [show, setShow] = useState(false);
    const [ticketData, setTicketData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [hasData, setHasData] = useState(false);
    const [autoCheck, setAutoCheck] = useState(false);
    const [play] = useSound(beepSound);
    const [error] = useSound(errorSound);

    const getEventData = async () => {
        await axios.get(`${api}event-ticket-info/${UserData?.id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                //  
                if (res.data.status) {
                    setEventData(res.data.data)
                }
            }).catch((err) => { })
    }

    useEffect(() => {
        getEventData()
    }, []);

    const getTicketDetail = async (data) => {
        await axios.post(`${api}verify-ticket/${data}`, {
            user_id: UserData?.reporting_user
        }, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                if (res.data.status) {
                    //console.log(res.data.bookings)
                    play()
                    setTicketData(res.data.bookings)
                    setShow(true)
                }
            }).catch((err) => {
                setQRData('')
                const formattedTime = formatDateTime(err.response.data?.time);
                const message = `${err.response.data?.time && `Check In: <strong>${formattedTime}</strong>`}`;
                Swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    html: `${message}`,
                    timer: 1000,
                });
                error()
            }
            )
    }

    useEffect(() => {
        if (QRdata.length === 9) {
            getTicketDetail(QRdata)
        }
    }, [QRdata])

    useEffect(() => {
        if ((Object.entries(ticketData)?.length || ticketData?.bookings?.length) > 0) {
            setHasData(true)
        }
    }, [ticketData])


    const handleVerify = async () => {
        if (QRdata) {
            await axios.get(`${api}chek-in/${QRdata}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
                .then((res) => {
                    //  
                    if (res.data.status) {
                        Sweetalert();
                        setQRData('')
                        setShow(false)
                        getEventData()
                    }
                }).catch((err) =>
                    SweetalertError(err.response.data.message)
                )
        }
    };
    
    function Sweetalert() {
        Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Ticket Scanned Successfully.",
            timer: 1000,
        });
    }
    function SweetalertError(data, subData) {
        Swal.fire({
            icon: "error",
            title: data,
            text: subData,
        });
    }

    useEffect(() => {
        if (show && autoCheck) {
            const timer = setTimeout(() => {
                handleVerify();
            }, 1500);

            // Cleanup function to clear the timeout if the effect is re-run or unmounted
            return () => clearTimeout(timer);
        }
    }, [show]);

    return (
        <Fragment>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title id="" className="text-center">
                        {hasData && (ticketData?.ticket?.event?.name || ticketData?.bookings[0]?.ticket?.event?.name || '')}
                    </Modal.Title>
                    <div className="border rounded">
                        <Card className="shadow-none mb-0">
                            <Card.Body>
                                <Row>
                                    <Col sm={6} md={4}>
                                        <h6>Number: </h6>
                                        <h6 className="mt-2">
                                            {hasData && ((ticketData?.number === null ? ' ' : ticketData?.number) || ticketData?.bookings[0]?.number || '')}
                                        </h6>
                                    </Col>
                                    <Col sm={6} md={4}>
                                        <h6>Name: </h6>
                                        <h6 className="mt-2">
                                            {hasData && ((ticketData?.name === null ? ' ' : ticketData?.name) || (ticketData?.bookings[0] && ticketData?.bookings[0]?.name) || '')}
                                        </h6>
                                    </Col>
                                    <Col sm={6} md={4}>
                                        <h6>Ticket :</h6>
                                        <p className="mt-2 mb-0">
                                            {hasData && (ticketData?.ticket?.name || ticketData?.bookings[0]?.ticket?.name || '')}
                                        </p>
                                    </Col>

                                    <Col sm={6} md={4}>
                                        <h6>QTY:</h6>
                                        <p className="mt-2 mb-0">
                                            {hasData && (ticketData?.bookings?.length || ticketData?.quantity || 1)}
                                        </p>
                                    </Col>
                                    <Col sm={6} md={4}>
                                        <h6>From:</h6>
                                        <p className="mt-2 mb-0">
                                            {hasData && (ticketData?.ticket?.event?.date_range?.split() || ticketData?.bookings[0]?.ticket?.event?.date_range?.split() || '')}
                                        </p>
                                    </Col>
                                    <Col sm={6} md={4}>
                                        <h6>To:</h6>
                                        <p className="mt-2 mb-0">
                                            {hasData && (ticketData?.ticket?.event?.date_range?.split() || ticketData?.bookings[0]?.ticket?.event?.date_range?.split() || '')}
                                        </p>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button onClick={handleVerify}>Verify</Button>
                </Modal.Footer>
            </Modal>
            <Row>
                <Row>
                    {eventData?.map((item, index) => (
                        <Col key={index} xl={6} md={6}>
                            <Card>
                                <Card.Header>{item?.event?.name}</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Card className="card-block card-stretch card-height">
                                                <Card.Body>
                                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                                        <span className="text-dark ">Checked</span>
                                                    </div>
                                                    <h2 className="counter p-0">
                                                        <CountUp
                                                            start={0}
                                                            end={item?.checked_bookings}
                                                            duration={1}
                                                            useEasing={true}
                                                            separator=","
                                                        />
                                                    </h2>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className="card-block card-stretch card-height">
                                                <Card.Body>
                                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                                        <span className="text-dark ">Remaining</span>
                                                    </div>
                                                    <h2 className="counter p-0">
                                                        <CountUp
                                                            start={0}
                                                            end={item?.remaining_bookings}
                                                            duration={1}
                                                            useEasing={true}
                                                            separator=","
                                                        />
                                                    </h2>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className="card-block card-stretch card-height">
                                                <Card.Body>
                                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                                        <span className="text-dark ">Total</span>
                                                    </div>
                                                    <h2 className="counter p-0">
                                                        <CountUp
                                                            start={0}
                                                            end={item?.total_bookings}
                                                            duration={1}
                                                            useEasing={true}
                                                            separator=","
                                                        />
                                                    </h2>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Row>
            <Row>
                <Col sm="12" lg="6">
                    <Card>
                        <div className="scanner">
                            <div className="scanne-by-device">
                                <Card.Body>
                                    <div className='d-flex justify-content-between'>
                                        <p>Scan the qr code by scanner</p>
                                        <div className='d-flex gap-2'>
                                            <Form.Check.Label htmlFor="flexSwitchCheckDefault">
                                                Auto Check
                                            </Form.Check.Label>
                                            <Form.Check className="form-switch">
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    className="me-2"
                                                    id="flexSwitchCheckDefault"
                                                    // checked={eventFeature}
                                                    onChange={(e) => setAutoCheck(e.target.checked)}
                                                />
                                            </Form.Check>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col md="6" lg="12" className='mb-3'>
                                            <Form.Control
                                                type="text"
                                                value={QRdata}
                                                onChange={(e) => setQRData(e.target.value)}
                                                placeholder="QR Data"
                                                maxLength={9}
                                                autoFocus
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
});
Scanner.displayName = "Scanner";
export default Scanner;
