
import axios from 'axios';
import { templates } from 'choices.js';
import { error } from 'pdf-lib';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';



// Create a context
const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [smsConfig, setSmsConfig] = useState([]);
  const [UserList, setUserList] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const api = process.env.REACT_APP_API_PATH;
  const UserData = useSelector((auth) => auth?.auth?.user);
  const UserPermissions = useSelector((auth) => auth?.auth?.user?.permissions)
  const authToken = useSelector((auth) => auth?.auth?.token);
  const userRole = UserData?.role;

  // template list 
  const GetEventSmsConfig = async (id) => {
    try {
      const res = await axios.get(`${api}sms-api/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + authToken,
        }
      });
      if (res.data.status) {
        const configData = res.data;
        setSmsConfig(configData?.config);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const Userslist = () => {
    axios.get(`${api}users`, {
      headers: {
        'Authorization': 'Bearer ' + authToken,
      }
    }).then(response => {
      setUserList(response.data.users);
    }).catch(error => {
      console.log(error);
    });
  }
  useEffect(() => {
    if (Object.keys(UserData).length > 0) {
      Userslist();
    }
    const userAgent = navigator.userAgent;
    setIsMobile(/Android|webOS|iPhone/i.test(userAgent));
  }, []);
  const DownloadExcelFile = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  }
  const HandleExport = async (path, fileName, id = null) => {
    let Eid = id;
    let Epath = path;
    let EfileName = fileName;
    await axios.get(`${api}${Epath}/${Eid ? Eid : UserData.id}`, {
      headers: {
        'Authorization': 'Bearer ' + authToken,
      },
    })
      .then(response => {
        const data = response.data?.data;
        const fName = EfileName;
        DownloadExcelFile(data, fName);
      })
      .catch(error => {
        //console.log(error);
      });
  }
  const handleMakeReport = async (number, message_id,) => {
    try {
      await axios.post(`${api}make-reports`, {
        message_id: message_id,
        waId: number,
        display_phone_number: UserData?.whatsapp_number
      },
        {
          headers: {
            'Authorization': 'Bearer ' + authToken,
          }
        });
    } catch (error) {
    }
  };

  const formatDateTime = (dateTime) => {
    // console.log(dateTime)
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = `${String(hours).padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;

    return `${day}-${month}-${year} | ${strTime}`;
  };

  const formateTemplateTime = (dateStr, time) => {
    if (!dateStr) return '';

    const [startDate, endDate] = dateStr.split(',');
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const start = new Date(startDate);
    const startFormatted = start.toLocaleDateString('en-US', options).replace(/,/g, '');

    if (endDate) {
      const end = new Date(endDate);
      const endFormatted = end.toLocaleDateString('en-US', options).replace(/,/g, '');
      const startDayMonth = startFormatted.split(' ').slice(0, 2).join(' ');
      return `${startDayMonth} to ${endFormatted} | ${time}`;
    } else {
      return `${startFormatted} | ${time}`;
    }
  };

  const successAlert = (title, subtitle) => {
    Swal.fire({
      icon: "success",
      title: title,
      text: subtitle,
    });
  }
  const ErrorAlert = (error) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
      backdrop: `rgba(60,60,60,0.8)`,
      // footer: '<a href="">Why do I have this issue?</a>',
    });
  }
  const AskAlert = (title, buttonText, SuccessMessage) => {
    Swal.fire({
      title: "Are you sure?",
      text: title,
      icon: "warning",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText: buttonText,
    }).then((result) => {
      if (result.isConfirmed && SuccessMessage) {
        Swal.fire(SuccessMessage);
      }
    });
  }
  function modifyNumber(number) {
    let mob_number = String(number);
    if (mob_number.length === 10) {
      mob_number = '91' + mob_number;
      return mob_number;
    } else if (mob_number.length === 12) {
      return number
    }
  }
  const sendTickets = (data, type) => {
    const number = data?.number ?? data?.bookings?.[0]?.number ?? 'Unknown';
    const thumbnail = data?.ticket?.event?.thumbnail ?? data?.bookings?.[0]?.ticket?.event?.thumbnail ?? 'https://smsforyou.biz/ticketcopy.jpg';
    const name = data?.user?.name ?? data?.bookings?.[0]?.user?.name ?? 'Guest';
    const qty = data?.bookings?.length ?? 1;
    const category = data?.ticket?.name ?? data?.bookings?.[0]?.ticket?.name ?? 'General';
    const eventName = data?.ticket?.event?.name ?? data?.bookings?.[0]?.ticket?.event?.name ?? 'Event';
    const ticketName = data?.ticket?.name ?? data?.bookings?.[0]?.ticket?.name ?? 'N/A';
    const eventDate = data?.ticket?.event?.date_range ?? data?.bookings?.[0]?.ticket?.event?.date_range ?? 'TBD';
    const eventTime = data?.ticket?.event?.start_time ?? data?.bookings?.[0]?.ticket?.event?.start_time ?? 'TBD';
    const DateTime = formateTemplateTime(eventDate, eventTime);
    const address = data?.ticket?.event?.address ?? data?.bookings?.[0]?.ticket?.event?.address ?? 'No Address Provided';
    const location = address.replace(/,/g, '|');

    const smsConfig = data?.ticket?.event?.user?.sms_config?.[0] ?? data?.bookings?.[0]?.ticket?.event?.user?.sms_config?.[0];
    const config_status = smsConfig?.status ? smsConfig?.status : '0';
    const organizerApiKey = smsConfig?.status === '1' ? smsConfig.api_key : 'null';
    const organizerSenderId = smsConfig?.status === '1' ? smsConfig.sender_id : 'null';

    const values = [name, eventName, qty, category, location, DateTime];
    // SMS message
    const oldMessage = `Booking Confirmed!
Hey ${name}
Download E-tickets for webinar by login using ${number} 
at s4y.in/xyz/xxxxxxx 
SMS4U`;

    const newMessage = `Booking Confirmed!
Hey ${name}
QTY ${qty}
Type ${ticketName}
Download E-tickets for ${truncateString(eventName)} by login using ${number}
at wwww.sevaktrust.org/dashboard/bookings
Sevak Trust
Get Your Ticket
SMS4U`
    const encodedMessage = newMessage;

    const sendSMSAndAlert = () => {
      HandleSendSMS(number, encodedMessage, organizerApiKey, organizerSenderId, config_status, name , qty,ticketName,eventName);
      handleWhatsappAlert(number, 'bookingconfirmed2', values, thumbnail);
    };

    if (type === 'old') {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to send the ticket again?",
        icon: "warning",
        showCancelButton: true,
        backdrop: `rgba(60,60,60,0.8)`,
        confirmButtonText: 'Send Ticket',
      }).then((result) => {
        if (result.isConfirmed) {
          sendSMSAndAlert();
        }
      });
    } else {
      sendSMSAndAlert();
    }
  };

  //external
  const handleWhatsappAlert = async (number, template, values = [], url) => {
    let modifiedNumber = modifyNumber(number);
    if (!Array.isArray(values)) {
      console.error("Values is not an array");
      return;
    }
    let value = values.join(",");
    await axios.post(`https://waba.smsforyou.biz/api/send-messages?apikey=6ibcui9bw7YbTa5YUnKv&to=${modifiedNumber}& type=T&tname=${template}&values=${value}&media_url=${url}`)
      .then(response => {
        if (response.data.status) {
          console.log(response.data)
          //successAlert('Success', 'Ticket Sent Successfully')
        }
      }).catch(error => {
        console.log(error);
      });
  }
  const HandleSendSMS = async (number, message, api_key, sender_id, config_status, name ,qty,ticketName,eventName) => {
    let modifiedNumber = modifyNumber(number);
    try {
      const response = await axios.post(`${api}send-sms`, {
        number: modifiedNumber,
        message: message,
        api_key: api_key,
        sender_id: sender_id,
        ticketName,
        eventName,
        config_status: config_status,
        qty,
        name
      },
        {
          headers: {
            'Authorization': 'Bearer ' + authToken,
          },
        }
      );
      // console.log('SMS sent successfully:', response.data);
    } catch (error) {
      // console.error('Error sending SMS:', error);
    }
  }

  const formatDateRange = (dateRange) => {
    if (!dateRange) return '';

    // Split the dateRange by commas
    const dates = dateRange.split(',').map(date => date.trim()); // Remove any extra spaces
    if (dates.length === 1) {
      // If there is only one date, return it formatted
      return `Single date: ${dates[0]}`;
    } else if (dates.length === 2) {
      // If there are two dates, format as a range
      const [startDate, endDate] = dates;
      return `${startDate} to ${endDate}`;
    } else {
      // If not exactly one or two dates, return the original string
      return dateRange;
    }
  };
  const convertTo12HourFormat = (time24) => {
    if (!time24) return '';

    const [hours, minutes] = time24.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12; // Convert hour to 12-hour format, with 12 instead of 0
    const minutesFormatted = minutes.toString().padStart(2, '0'); // Format minutes with leading zero

    return `${hours12}:${minutesFormatted} ${period}`;
  };

  function truncateString(str, num = 9) {
    if (str.length > num) {
      return str.slice(0, num) + '...';
    }
    return str;
  }



  const contextValue = {
    api,
    authToken,
    formatDateRange,
    UserData,
    userRole,
    UserList,
    UserPermissions,
    handleMakeReport,
    DownloadExcelFile,
    HandleExport,
    HandleSendSMS,
    isMobile,
    formatDateTime,
    successAlert,
    ErrorAlert,
    AskAlert,
    handleWhatsappAlert,
    sendTickets,
    GetEventSmsConfig,
    formateTemplateTime,
    convertTo12HourFormat,
    truncateString
  };

  return <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>;
};
export const useMyContext = () => useContext(MyContext);
