import React, { useState, memo, useEffect } from "react";

// React-bootstrap
import {
    Row,
    Col,
    Image,
    Nav,
    Tab,
    Form,
    Button,
    // Flex,
    Table,
    Card,
    Container,
    Modal,
    Alert,
} from "react-bootstrap";

// Router
import { Link, useNavigate, useParams } from "react-router-dom";

import ShareOffcanvas from "../../../../../components/partials/components/shareOffcanvasNew";
import partyImage from "../../../../../assets/modules/e-commerce/images/product/party3.jpg";
import axios from "axios";
import { useMyContext } from "../../../../../Context/MyContextProvider";
import { useDispatch } from "react-redux";
import { logout, signIn } from "../../../../../store/slices/authSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
// import { FaWhatsapp } from "react-icons/fa";

const EventDetail = memo(() => {
    const { api, UserData, isMobile, authToken, successAlert, formatDateRange, convertTo12HourFormat } = useMyContext();

    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [event, setEvent] = useState([]);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [credential, setCredential] = useState('')

    const [modelShow, setModelShow] = useState(false);
    const [signUp, setSignUp] = useState(true);
    // otp state 
    const [otpState, setOtpState] = useState(false);
    const [otp, setOTP] = useState('');
    const [attempts, setAttempts] = useState(0);
    const [timerVisible, setTimerVisible] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        scrollToTop()
        axios.get(`${api}event-detail/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                setEvent(res.data.events)
            }
        }).catch((err) =>
            console.log(err)
        )
    }, []);


    const handleBooking = async () => {
        if (Object.keys(UserData).length > 0) {
            navigate('/events/' + id + '/process');
        } else {
            setModelShow(true)
        }
    }
    const handleSignUp = async () => {

        await axios.post(`${api}create-user`, {
            'name': name,
            'email': email,
            'number': number,
            'password': number,
            'role_id': 4
        }, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                setOtpState(true)
                setSignUp(false)
                setCredential(number)
                handleLogin(number)
            }
        }).catch((err) => {
            setError(err?.response?.data?.error)
            //console.log(err)
            //handleBack()
        }
        )
    }


    useEffect(() => {
        let timer;
        if (timerVisible && countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [timerVisible, countdown]);

    useEffect(() => {
        if (countdown === 0) {
            setTimerVisible(false);
        }
    }, [countdown]);

    const handleVerifyOtp = async () => {
        if (otp) {
            const data = { otp, number: credential }
            const user = await dispatch(signIn(data))
            if (user?.type === 'login/fulfilled') {
                successAlert('Success', 'Login Successfully')
                navigate('/events/' + id + '/process');
            } else {
                setError(user?.payload)
            }
        } else {
            setAttempts(prevAttempts => prevAttempts + 1);
            if (attempts >= 2) {
                dispatch(logout())
            }
        }
    };

    const handleBack = async () => {
        setOtpState(false)
        setSignUp(false)
    }



    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');
    const handleLogin = async (data) => {
        setLoading(true)
        let crd = credential || data
        if (!crd) {
            setLoading(false)
            setError('Please Enter The Mobile No / Email Address')
        }
        else {
            try {
                const response = await axios.post(`${api}verify-user`, { data: crd })
                if (response.data.status) {
                    setError(false)
                    setLoading(false)
                    setTimerVisible(true)
                    setCountdown(30)
                    setOtpState(true)
                    setOtpSent(true)
                    setOTP('')
                }
            } catch (err) {
                setLoading(false)
                setError(err.response.data.error)
            }
        }
    }
    const handleClose = () => {
        setModelShow(false)
        setError('')
        setOTP('')
        setEmail('')
        setEmail('')
        setNumber('')
        setLoading(false)
    }
    const disable = event?.booking_not_start || event?.booking_close
    return (
        <Container>
            {/* //login model */}
            <Modal show={modelShow}
                backdrop="static" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    {otpState ? (
                        <h5 className="text-center m-0">OTP Verification</h5>
                    ) : signUp && !otpState ? (
                        <h5 className="text-center m-0">Sign Up</h5>
                    ) : (
                        <h5 className="text-center m-0">Sign In</h5>
                    )}
                </Modal.Header>
                <Modal.Body>
                    {
                        otpState ?
                            <div className="p-3">
                                <div className="form-group">
                                    {error &&
                                        <Alert variant="danger d-flex align-items-center" role="alert">
                                            <svg className="me-2" id="exclamation-triangle-fill" fill="currentColor" width="20" viewBox="0 0 16 16">
                                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                            </svg>
                                            <div>
                                                {error}
                                            </div>
                                        </Alert>
                                    }
                                    <input
                                        type="number"
                                        value={otp}
                                        className="form-control mb-0"
                                        placeholder="Plase Enter Your OTP"
                                        onChange={(e) => setOTP(e.target.value)}
                                    />
                                </div>
                                <div className="d-flex gap-3 justify-content-center">
                                    <div className="text-center pb-3">
                                        <Button type="button" variant="secondary"
                                            onClick={() => handleVerifyOtp()}
                                        >Submit</Button>
                                    </div>
                                    <div className="text-center pb-3">
                                        <Button type="button" variant="primary"
                                            onClick={handleBack}
                                        >Change Number</Button>
                                    </div>

                                </div>
                                <div className="text-center pb-3">
                                    <p className="my-3 fs-6">OTP sent on your Mobile No and Email</p>
                                    {
                                        timerVisible && otpSent ? (
                                            <div>
                                                <p>Resend OTP in {countdown} seconds</p>
                                            </div>
                                        )
                                            :
                                            <Button onClick={() => handleLogin()}>Resend OTP</Button>
                                    }
                                </div>
                            </div>

                            :
                            (signUp && !otpState) ?
                                <>
                                    <div className="p-3">
                                        {error &&
                                            <Alert variant="danger d-flex align-items-center" role="alert">
                                                <svg className="me-2" id="exclamation-triangle-fill" fill="currentColor" width="20" viewBox="0 0 16 16">
                                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                                </svg>
                                                <div>
                                                    {error}
                                                </div>
                                            </Alert>
                                        }
                                        <Row className="d-flex justify-content-between">
                                            <Col sm="12" md="6" className="form-group">
                                                <Form.Label className="form-label" htmlFor="firstName" >Name</Form.Label>
                                                <input type="text" className="form-control mb-0" id="firstName" placeholder="Enter Name" onChange={(e) => setName(e.target.value)} />
                                            </Col>
                                            <div className="form-group col-sm-12 col-md-6">
                                                <Form.Label className="form-label" htmlFor="Phone_NO" >Phone No.</Form.Label>
                                                <input type="number" className="form-control mb-0" id="Phone_NO" placeholder="Enter Phone Number" onChange={(e) => setNumber(e.target.value)} />
                                            </div>
                                        </Row>
                                        <Row className="d-flex justify-content-between">
                                            <Col sm="12" md="12" className="form-group">
                                                <Form.Label className="form-label" htmlFor="Emailid" >Email</Form.Label>
                                                <input type="email" className="form-control mb-0" id="Emailid" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} />
                                            </Col>
                                        </Row>
                                        <div className="text-center pb-3">
                                            <input type="checkbox" className="form-check-input" id="aggrement-hopeui" />
                                            <Form.Label className="form-check-label ms-1" htmlFor="aggrement-hopeui">I agree with the terms of use</Form.Label>
                                        </div>
                                        <div className="text-center pb-3">
                                            <Button type="button" className="btn btn-secondary"
                                                onClick={handleSignUp}
                                            >Sign Up</Button>
                                        </div>
                                    </div>
                                    <p className="text-center">Already have an Account<Link to="#" className="ms-2" onClick={() => setSignUp(!signUp)}>Sign in</Link></p>
                                </>
                                : (!signUp && !otpState) &&

                                <>
                                    <div className="p-3">
                                        <div className="form-group">
                                            {error &&
                                                <Alert variant="danger d-flex align-items-center" role="alert">
                                                    <svg className="me-2" id="exclamation-triangle-fill" fill="currentColor" width="20" viewBox="0 0 16 16">
                                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                                    </svg>
                                                    <div>
                                                        {error}
                                                    </div>
                                                </Alert>
                                            }
                                            <input type="email" className="form-control mb-0" id="email-id" placeholder="Enter email or mobile number" onChange={(e) => setCredential(e.target.value)} />
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Form.Check className="form-check d-inline-block pt-1 mb-0">
                                                <input type="checkbox" className="form-check-input" id="customCheck11" />
                                                <Form.Label className="form-check-label" htmlFor="customCheck11">Remember Me</Form.Label>
                                            </Form.Check>
                                            <Link to="/auth/reset-password">Forgot password</Link>
                                        </div>
                                        <div className="text-center pb-3">
                                            <Button type="button" className="secondary" disabled={loading} onClick={() => handleLogin()}>{loading ? 'Please Wait...' : 'Sign in'}</Button>
                                        </div>
                                    </div>
                                    <p className="text-center">Don't have account?<Link to="#" className="ms-2" onClick={() => setSignUp(!signUp)}> Click here to sign up.</Link></p>
                                </>
                    }
                </Modal.Body>
            </Modal>
            {/* //login model end*/}
            <Row>
                <Col lg={12}>
                    {
                        isMobile &&
                        <Link onClick={() => !disable && handleBooking()}>
                            <div className={`d-flex py-4 flex-wrap gap-4 w-100 justify-content-center ${!disable && 'bg-secondary'}`}
                                style={{
                                    position: 'fixed',
                                    background: disable && '#E57C93',
                                    left: '0',
                                    zIndex: '99',
                                    bottom: '0',
                                }}
                            >
                                <Link className="text-white d-flex align-items-center gap-2 w-100 justify-content-center p-0">
                                    <span className="btn-inner d-flex ">
                                        <svg
                                            width="20"
                                            className="icon-20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.4"
                                                d="M16.6203 22H7.3797C4.68923 22 2.5 19.8311 2.5 17.1646V11.8354C2.5 9.16894 4.68923 7 7.3797 7H16.6203C19.3108 7 21.5 9.16894 21.5 11.8354V17.1646C21.5 19.8311 19.3108 22 16.6203 22Z"
                                                fill="currentColor"
                                            ></path>
                                            <path
                                                d="M15.7551 10C15.344 10 15.0103 9.67634 15.0103 9.27754V6.35689C15.0103 4.75111 13.6635 3.44491 12.0089 3.44491C11.2472 3.44491 10.4477 3.7416 9.87861 4.28778C9.30854 4.83588 8.99272 5.56508 8.98974 6.34341V9.27754C8.98974 9.67634 8.65604 10 8.24487 10C7.8337 10 7.5 9.67634 7.5 9.27754V6.35689C7.50497 5.17303 7.97771 4.08067 8.82984 3.26285C9.68098 2.44311 10.7814 2.03179 12.0119 2C14.4849 2 16.5 3.95449 16.5 6.35689V9.27754C16.5 9.67634 16.1663 10 15.7551 10Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </span>
                                    Book Now
                                </Link>
                            </div>
                        </Link>
                    }
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col lg="12">
                                    <Row className="align-items-center">
                                        <Col lg="5">
                                            <div className="product-vertical-slider">
                                                <div className="d-flex justify-content-center">
                                                    <Image
                                                        width={isMobile ? 250 : 400}
                                                        src={event?.thumbnail || partyImage}
                                                        alt="product-details"
                                                        className="img-fluid iq-product-img hover-media rounded-5"
                                                    />
                                                </div>
                                            </div>
                                            <div className="contacts text-center my-3">
                                                <p>
                                                    <i className="fa-brands fa-whatsapp text-success me-2" />
                                                    Say <strong>Hii</strong> to +91 8000-40-8888
                                                </p>
                                                <p>
                                                    <FontAwesomeIcon icon={faPhone} className="me-2" />
                                                    Give us a Missed call: +91 8000-40-8888
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg="7" className="mt-4 mt-lg-0">
                                            <div className="border-bottom">
                                                <div className="d-flex flex-column align-content-between justify-items-center mb-3">
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <h4 className="mb-0">{event?.name}</h4>
                                                        <div className="d-flex justify-content-end ">
                                                            <ShareOffcanvas share={true} />
                                                        </div>
                                                    </div>

                                                </div>
                                                {
                                                    event?.booking_not_start ?
                                                        <p className="text-danger">Booking Not Started Yet</p> :
                                                        event?.booking_close ?
                                                            <p className="text-danger">Booking Closed</p> :
                                                            <div className="d-flex align-items-center">
                                                                {/* <div className="old-price">
                                                                    <h4 className="mb-0"> Price:&nbsp;</h4>
                                                                    <div className="d-flex">
                                                                        {
                                                                            event?.lowest_sale_price && Number(event?.lowest_ticket_price) > Number(event?.lowest_sale_price) ?
                                                                                <>
                                                                                    <h4 className="mb-0">
                                                                                        {' ₹' + event?.lowest_sale_price}
                                                                                    </h4>
                                                                                    <p className="mb-0" style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
                                                                                        {'₹' + event?.lowest_ticket_price}
                                                                                    </p>
                                                                                </>
                                                                                :
                                                                                <h4 className="mb-0">
                                                                                    {' ₹' + event?.lowest_ticket_price}
                                                                                </h4>

                                                                        }
                                                                    </div>
                                                                    <h6 className="m-0 ms-2">
                                                                        onwards
                                                                    </h6>
                                                                </div> */}
                                                                <div className="table-responsive  w-100">
                                                                    <Table responsive striped className="mb-0" role="grid">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Ticket Category</th>
                                                                                <th>Price</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                event?.tickets?.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{item.name}</td>
                                                                                            <td>{
                                                                                                item.sale === 'true' ?
                                                                                                    <div className="d-flex gap-2">
                                                                                                        <p className="mb-0" style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
                                                                                                            {'₹' + item?.price}
                                                                                                        </p>
                                                                                                        <p className="mb-0">
                                                                                                            {' ₹' + item?.sale_price}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <p className="mb-0">
                                                                                                        {' ₹' + item?.price}
                                                                                                    </p>

                                                                                            }</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                }
                                            </div>
                                            <div className="d-flex gap-2 border-bottom">
                                                <p className="py-4 mb-0">
                                                    <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" height="32"><path fillRule="evenodd" clipRule="evenodd" d="M8.53162 2.93677C10.7165 1.66727 13.402 1.68946 15.5664 2.99489C17.7095 4.32691 19.012 6.70418 18.9998 9.26144C18.95 11.8019 17.5533 14.19 15.8075 16.0361C14.7998 17.1064 13.6726 18.0528 12.4488 18.856C12.3228 18.9289 12.1848 18.9777 12.0415 19C11.9036 18.9941 11.7693 18.9534 11.6508 18.8814C9.78243 17.6746 8.14334 16.134 6.81233 14.334C5.69859 12.8314 5.06584 11.016 5 9.13442C4.99856 6.57225 6.34677 4.20627 8.53162 2.93677ZM9.79416 10.1948C10.1617 11.1008 11.0292 11.6918 11.9916 11.6918C12.6221 11.6964 13.2282 11.4438 13.6748 10.9905C14.1214 10.5371 14.3715 9.92064 14.3692 9.27838C14.3726 8.29804 13.7955 7.41231 12.9073 7.03477C12.0191 6.65723 10.995 6.86235 10.3133 7.55435C9.63159 8.24635 9.42664 9.28872 9.79416 10.1948Z" fill="currentColor"></path><ellipse opacity="0.4" cx="12" cy="21" rx="5" ry="1" fill="currentColor"></ellipse></svg>
                                                </p>
                                                <p className="py-4 mb-0">
                                                    {event?.address}
                                                </p>
                                            </div>
                                            <div className="d-flex flex-column py-4">
                                                <div className="d-flex align-items-center mb-3">
                                                    <span className="text-dark">Event Date:</span>
                                                    <span className="text-primary  ms-2">
                                                        {formatDateRange(event?.date_range)}
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center mb-3">
                                                    <span className="text-dark">Starts At:</span>
                                                    <span className="text-primary  ms-2">{convertTo12HourFormat(event?.start_time)}</span>
                                                </div>
                                            </div>
                                            {
                                                !isMobile &&
                                                <div>
                                                    <div className={`d-flex py-4 flex-wrap gap-4 ${disable && 'opacity-50'}`}
                                                        onClick={() => !disable && handleBooking()}>
                                                        <Link
                                                            disabled={disable}
                                                            className="btn btn-warning d-flex align-items-center gap-2"
                                                        >
                                                            <span className="btn-inner d-flex ">
                                                                <svg
                                                                    width="20"
                                                                    className="icon-20"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        opacity="0.4"
                                                                        d="M16.6203 22H7.3797C4.68923 22 2.5 19.8311 2.5 17.1646V11.8354C2.5 9.16894 4.68923 7 7.3797 7H16.6203C19.3108 7 21.5 9.16894 21.5 11.8354V17.1646C21.5 19.8311 19.3108 22 16.6203 22Z"
                                                                        fill="currentColor"
                                                                    ></path>
                                                                    <path
                                                                        d="M15.7551 10C15.344 10 15.0103 9.67634 15.0103 9.27754V6.35689C15.0103 4.75111 13.6635 3.44491 12.0089 3.44491C11.2472 3.44491 10.4477 3.7416 9.87861 4.28778C9.30854 4.83588 8.99272 5.56508 8.98974 6.34341V9.27754C8.98974 9.67634 8.65604 10 8.24487 10C7.8337 10 7.5 9.67634 7.5 9.27754V6.35689C7.50497 5.17303 7.97771 4.08067 8.82984 3.26285C9.68098 2.44311 10.7814 2.03179 12.0119 2C14.4849 2 16.5 3.95449 16.5 6.35689V9.27754C16.5 9.67634 16.1663 10 15.7551 10Z"
                                                                        fill="currentColor"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                            Book Now
                                                        </Link>
                                                    </div>
                                                </div>
                                            }

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Tab.Container defaultActiveKey="first">
                                <Nav className="tab-bottom-bordered">
                                    <div
                                        className="mb-0 nav nav-tabs"
                                        id="nav-tab1"
                                        role="tablist"
                                    >
                                        <Nav.Link
                                            eventKey="first"
                                            id="nav-description-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-description"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-description"
                                            aria-selected="true"
                                        >
                                            Description
                                        </Nav.Link>
                                        <Nav.Link
                                            id="nav-info-tab"
                                            eventKey="second"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-info"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-info"
                                            aria-selected="false"
                                        >
                                            Terms & Condition
                                        </Nav.Link>
                                        <Nav.Link
                                            id="nav-review-tab"
                                            eventKey="thired"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-review"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-review"
                                            aria-selected="false"
                                        >
                                            Media
                                        </Nav.Link>
                                    </div>
                                </Nav>
                                <Tab.Content
                                    className="mt-4 iq-tab-fade-up"
                                    id="nav-tabContent"
                                >
                                    <Tab.Pane
                                        eventKey="first"
                                        id="nav-description"
                                        role="tabpanel"
                                        aria-labelledby="nav-description-tab"
                                    >
                                        <div className="d-flex flex-column">
                                            <p className="mb-0" dangerouslySetInnerHTML={{ __html: event?.description }}></p>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="second"
                                        id="nav-info"
                                        role="tabpanel"
                                        aria-labelledby="nav-info-tab"
                                    >
                                        <Table bordered className="mb-0" responsive>
                                            <tbody>
                                                <tr>
                                                    <td className="mb-0" dangerouslySetInnerHTML={{ __html: event?.ticket_terms?.replace(/\r\n/g, '<br />') }} />
                                                </tr>
                                            </tbody>

                                        </Table>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="thired"
                                        id="nav-review"
                                        role="tabpanel"
                                        aria-labelledby="nav-review-tab"
                                    >
                                        <Row className="row-cols-2 row-cols-lg-5 py-3">
                                            <Col>
                                                <ReactPlayer
                                                    url={event?.youtube_url}
                                                    controls
                                                />
                                            </Col>
                                            {/* <Col>
                                                <Image
                                                    src={img2}
                                                    alt="product-details"
                                                    className="img-fluid iq-product-img rounded-2  w-100 "
                                                    loading="lazy"
                                                />
                                            </Col>
                                            <Col>
                                                <Image
                                                    src={img3}
                                                    alt="product-details"
                                                    className="img-fluid iq-product-img rounded-2 w-100  "
                                                    loading="lazy"
                                                />
                                            </Col>
                                            <Col>
                                                <Image
                                                    src={img4}
                                                    alt="product-details"
                                                    className="img-fluid iq-product-img rounded-2  w-100 "
                                                    loading="lazy"
                                                />
                                            </Col>
                                            <Col>
                                                <Image
                                                    src={img5}
                                                    alt="product-details"
                                                    className="img-fluid iq-product-img rounded-2  w-100  "
                                                    loading="lazy"
                                                />
                                            </Col> */}
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
});
EventDetail.displayName = "EventDetail";
export default EventDetail;
