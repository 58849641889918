import React, { Fragment, memo, useState } from "react";
import { Nav, Offcanvas, Container, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CustomMenu from "./CustomMenu";

const LandingOffcanvasHeader = memo(() => {
  // const { isMobile }
  const [open, setOpen] = useState(false);
  let location = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);
  return (
    <Fragment>
      <button
        data-trigger="navbar_main"
        className="d-xl-none btn btn-primary rounded-pill p-1 pt-0"
        type="button"
        onClick={handleShow}
      >
        <svg width="20px" height="20px" viewBox="0 0 24 24" className="icon-20">
          <path
            fill="currentColor"
            d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
          ></path>
        </svg>
      </button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="mobile-offcanvas nav navbar navbar-expand-xl hover-nav py-0"
        style={{
          background: 'linear-gradient(to bottom, #17132E 0%, rgba(23, 19, 46, 0.5) 100%)',
          backdropFilter: 'blur(10px)', // Adjust blur radius as needed
          WebkitBackdropFilter: 'blur(10px)', // For Safari
        }}
      >
        <Container fluid className="p-lg-0 ">
          <Offcanvas.Header closeButton className="px-0 mx-3">
            <Link to="home" className="navbar-brand ms-3">
              {/* <svg
                className="icon-30 text-primary"
                width="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="-0.757324"
                  y="19.2427"
                  width="28"
                  height="4"
                  rx="2"
                  transform="rotate(-45 -0.757324 19.2427)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="7.72803"
                  y="27.728"
                  width="28"
                  height="4"
                  rx="2"
                  transform="rotate(-45 7.72803 27.728)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="10.5366"
                  y="16.3945"
                  width="16"
                  height="4"
                  rx="2"
                  transform="rotate(45 10.5366 16.3945)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="10.5562"
                  y="-0.556152"
                  width="28"
                  height="4"
                  rx="2"
                  transform="rotate(45 10.5562 -0.556152)"
                  fill="currentColor"
                ></rect>
              </svg>
              <h5 className="logo-title">Get Your Ticket</h5> */}
              {/* <Image src={'https://getyourticket.in/wp-content/uploads/2024/07/gyt-300x133.png'} alt="logo" loading="lazy" width={120}/> */}
            </Link>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="landing-header">
              <Nav
                as="ul"
                className="navbar-nav iq-nav-menu  list-unstyled"
                id="header-menu"
              >
                <CustomMenu handleClose={handleClose}/>
              </Nav>
            </div>
          </Offcanvas.Body>
        </Container>
      </Offcanvas>
    </Fragment>
  );
});

export default LandingOffcanvasHeader;
