import React, { memo, Fragment, useRef, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Card, Modal, Button } from "react-bootstrap";

import $ from "jquery";


import axios from "axios";
import QRCode from "react-qr-code";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useMyContext } from "../../../../../Context/MyContextProvider";
import useDataTable from "../../../../../components/hooks/useDatatable";


const AdminBookings = memo(() => {
    const { api, UserData, formatDateTime, sendTickets, authToken, truncateString } = useMyContext();
    const [bookings, setBookings] = useState([]);
    const [bookingData, setBookingData] = useState([]);

    const [modelState, setModelState] = useState("");
    const [loading, setLoading] = useState(true);
    const GetBookings = async () => {
        await axios
            .get(`${api}master-bookings/${UserData?.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
            .then((res) => {
                if (res.data.status) {
                    let data = res.data.bookings;
                    const filteredBookings = data.filter(booking =>
                        booking.bookings && Array.isArray(booking.bookings) && booking.bookings.length > 0
                    );
                    const normalBooking = data.filter(booking => !booking.bookings)
                    const allBookings = [...filteredBookings, ...normalBooking];
                    allBookings.sort((a, b) => {
                        const dateA = new Date(a.created_at);
                        const dateB = new Date(b.created_at);
                        return dateB.getTime() - dateA.getTime();
                    });
                    setBookings(allBookings);
                   // setBookings(res.data.bookings);
                    setLoading(!loading)
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        GetBookings();
    }, []);

    const listtableRef = useRef(null);
    const columns = useRef([
        // {
        //     data: null, // No direct data mapping
        //     orderable: false,
        //     title: "#",
        //     render: (data, type, row, meta) => meta.row + 1 // Use index + 1 as OrderId
        // },
        {
            data: null,
            title: "id",
            render: function (row) {
                return row.id;
            },
        },
        {
            data: null,
            title: "Event Name",
            render: function (row) {
                return `<p title=${row?.bookings?.[0]?.event_name || row?.event_name || ""}>${truncateString(row?.bookings?.[0]?.event_name || row?.event_name || "")}</p>`;
            },
        },
        {
            data: null,
            title: "Org Name",
            render: function (row) {
                return row?.bookings?.[0]?.organizer || row?.organizer || "";
            },
        },
        {
            data: null,
            title: "Attendee",
            render: function (row) {
                return row?.bookings?.[0]?.user?.name || row?.user?.name || "";
            },
        },
        {
            data: null,
            title: "Number",
            render: function (row) {
                return row?.bookings?.[0]?.number || row?.number || "";
            },
        },
        {
            data: null,
            title: "Ticket",
            render: function (row) {
                return row?.bookings?.[0]?.ticket?.name || row?.ticket?.name || "";
            },
        },
        {
            data: null,
            title: "Qty",
            render: function (row) {
                return row?.bookings?.length || 1;
            },
        },
        {
            data: null,
            title: "Disc",
            render: function (row) {
                return (
                    row?.discount || (row?.bookings && row?.bookings[0]?.discount) || 0
                );
            },
        },
        {
            data: null,
            title: "B Amt",
            render: function (row) {
                return (row?.bookings && row?.bookings[0]?.base_amount) || row?.base_amount || 0;
            },
        },
        {
            data: null,
            title: "C Fee",
            render: function (row) {
                return (row?.bookings && row?.bookings[0]?.convenience_fee) || row?.convenience_fee || 0;
            },
        },
        {
            data: null,
            title: "Total",
            render: function (row) {
                return (row?.bookings && row?.bookings[0]?.amount) || row?.amount || 0;
            },
        },
        {
            data: null,
            orderable: false,
            searchable: false,

            title: "Disable",
            render: function (data) {
                const Checked = data?.is_deleted === true && "checked";
                return `<div class="flex align-items-center list-user-action">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" data-table="action" data-id=${data?.id} data-method="disable" ${Checked}>
                            </div>
                        </div>`;
            },
        },
        {
            data: "created_at",
            title: "Purchase Date",
            render: function (data) {
                return formatDateTime(data);
            },
        },
        {
            data: null,
            title: "Status",
            render: function (data) {
                const status = data.status || (data.bookings && data.bookings[0]?.status);
                return `<span 
                        class="badge 
                        p-1 
                        bg-${status === "0" ? "warning" : "success"}">
                        ${status === "0" ? "Uncheck" : "Checked"}
                    </span>`;
            },
        },
        {
            data: null,
            orderable: false,
            searchable: false,
            title: "Action",
            render: function (data) {
                const isDisabled =
                    data?.is_deleted === true || (data.bookings && data.bookings[0]?.status) === "1"
                        ? "disabled"
                        : "";
                return `<div class="flex align-items-center list-user-action">
                            <button class="btn btn-sm btn-icon btn-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Resend" data-id=${data?.id} data-method="Print" data-table="action" ${isDisabled}>
                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" height="20"><path d="M21.4274 2.5783C20.9274 2.0673 20.1874 1.8783 19.4974 2.0783L3.40742 6.7273C2.67942 6.9293 2.16342 7.5063 2.02442 8.2383C1.88242 8.9843 2.37842 9.9323 3.02642 10.3283L8.05742 13.4003C8.57342 13.7163 9.23942 13.6373 9.66642 13.2093L15.4274 7.4483C15.7174 7.1473 16.1974 7.1473 16.4874 7.4483C16.7774 7.7373 16.7774 8.2083 16.4874 8.5083L10.7164 14.2693C10.2884 14.6973 10.2084 15.3613 10.5234 15.8783L13.5974 20.9283C13.9574 21.5273 14.5774 21.8683 15.2574 21.8683C15.3374 21.8683 15.4274 21.8683 15.5074 21.8573C16.2874 21.7583 16.9074 21.2273 17.1374 20.4773L21.9074 4.5083C22.1174 3.8283 21.9274 3.0883 21.4274 2.5783Z" fill="currentColor"></path><path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M3.01049 16.8079C2.81849 16.8079 2.62649 16.7349 2.48049 16.5879C2.18749 16.2949 2.18749 15.8209 2.48049 15.5279L3.84549 14.1619C4.13849 13.8699 4.61349 13.8699 4.90649 14.1619C5.19849 14.4549 5.19849 14.9299 4.90649 15.2229L3.54049 16.5879C3.39449 16.7349 3.20249 16.8079 3.01049 16.8079ZM6.77169 18.0003C6.57969 18.0003 6.38769 17.9273 6.24169 17.7803C5.94869 17.4873 5.94869 17.0133 6.24169 16.7203L7.60669 15.3543C7.89969 15.0623 8.37469 15.0623 8.66769 15.3543C8.95969 15.6473 8.95969 16.1223 8.66769 16.4153L7.30169 17.7803C7.15569 17.9273 6.96369 18.0003 6.77169 18.0003ZM7.02539 21.5683C7.17139 21.7153 7.36339 21.7883 7.55539 21.7883C7.74739 21.7883 7.93939 21.7153 8.08539 21.5683L9.45139 20.2033C9.74339 19.9103 9.74339 19.4353 9.45139 19.1423C9.15839 18.8503 8.68339 18.8503 8.39039 19.1423L7.02539 20.5083C6.73239 20.8013 6.73239 21.2753 7.02539 21.5683Z" fill="currentColor"></path></svg>
                            </button>
                        </div>`;
            },
        },
    ]);
    //     <button  data-id=${data?.id} data-method="edit" data-table="action" class="btn btn-sm btn-icon btn-warning" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
    //     <svg class="icon-20" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    //         <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    //         <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    //     </svg>
    // </button>
    useDataTable({
        tableRef: listtableRef,
        columns: columns.current,
        data: bookings,
        actionCallback: (data) => {
            switch (data.method) {
                case "Print":
                    HandleSendTicket(data?.id);
                    break;
                case "edit":
                    console.log("hello");
                    break;
                case "disable":
                    DeleteBooking(data?.id);
                    break;
                default:
                    break;
            }
        },
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });

    //   the function
    const HandleSendTicket = (id) => {
        let data = bookings?.find((item) => item?.id === id);
        //console.log(data)
        sendTickets(data, "old");
    };

    const DeleteBooking = async (id) => {
        let data = bookings?.find((item) => item?.id === id);
        if (data?.is_deleted === true) {
            await axios
                .get(`${api}restore-booking/${data?.token || data?.order_id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                })
                .then((res) => {
                    if (res.data.status) {
                        GetBookings();
                        Swal.fire({
                            icon: "success",
                            title: "Ticket Enabled!",
                            text: "Ticket enabled succesfully.",
                        });
                    }
                })
                .catch((err) => console.log(err));
        } else {
            await axios
                .delete(`${api}delete-booking/${data?.token || data?.order_id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                })
                .then((res) => {
                    if (res.data.status) {
                        GetBookings();
                        Swal.fire({
                            icon: "success",
                            title: "Ticket Disabled!",
                            text: "Ticket disabled succesfully.",
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const [showPrintModel, setShowPrintModel] = useState(false);
    const printRef = useRef();
    const handlePrint = () => {
        const printContent = printRef.current.innerHTML;
        const originalContent = document.body.innerHTML;

        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
        window.location.reload(); // to reload the original page content
    };
    return (
        <Fragment>
            {/* print model  */}
            <Modal
                show={showPrintModel}
                onHide={() => setShowPrintModel(false)}
                size="sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body ref={printRef}>
                    <div
                        style={{ textAlign: "center", color: "black", fontWeight: "bold" }}
                    >
                        {/* <h4>{event?.name}</h4> */}
                        <div className="qr">
                            <QRCode
                                size={150}
                                style={{ height: "auto" }}
                                className=""
                                value={bookingData?.token}
                            />
                        </div>
                        <span>{bookingData?.id}</span>
                        <p>{formatDateTime(bookingData?.created_at)}</p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-black">Qty</th>
                                    <th className="text-black">Ticket Name</th>
                                    <th className="text-black">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-black">{bookingData?.quantity}</td>
                                    <td className="text-black">{bookingData?.ticket?.name}</td>
                                    <td className="text-black">
                                        {bookingData?.ticket?.price * bookingData?.quantity}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td className="text-black p-0 text-end">SUBTOTAL</td>
                                    <td className="text-black py-0 text-end">
                                        ₹{bookingData?.ticket?.price * bookingData?.quantity}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td className="text-black p-0 text-end">TOTAL TAX</td>
                                    <td className="text-black py-0 text-end">
                                        ₹
                                        {Math.max(
                                            0,
                                            bookingData?.amount -
                                            -bookingData?.discount -
                                            bookingData?.ticket?.price * bookingData?.quantity
                                        ).toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td className="text-black p-0 text-end">DISCOUNT</td>
                                    <td className="text-black py-0 text-end">
                                        ₹{bookingData?.discount}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td className="text-black text-end pe-0">TOTAL</td>
                                    <td className="text-black text-end">
                                        ₹{bookingData?.amount}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p style={{ fontSize: "14px" }}>
                            Thank You for Payment Type
                            <br />
                            Please Come Again
                        </p>
                        <p style={{ fontSize: "14px" }}>Powered by - GetYourTicket.co.in</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPrintModel(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handlePrint()}>
                        Print Invoice
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* print model end */}
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Online Bookings</h4>
                            </div>
                            <div className="button">
                                <h4 className="card-title">
                                    <Link to={"/"}>
                                        <Button className="me-4 hvr-curl-top-right border-0">
                                            Book Ticket
                                        </Button>
                                    </Link>
                                </h4>
                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table
                                    id="datatable-ecom"
                                    ref={listtableRef}
                                    className="data-tables table custom-table movie_table"
                                ></table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
});

AdminBookings.displayName = "AdminBookings";
export default AdminBookings;
